import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { Typography } from '/design-systems/Atoms/Typography';
import { ShortCountdownRenderer } from '/design-systems/Atoms/DateCountDown/ShortCountdownRenderer';
import { Team } from '/design-systems/Molecules/Team';

import { GalleryProjectSection } from './GalleryProjectSection';

import { emptyArray } from '/utils/object';
import { toShort18 } from '/utils/string';
import { mergeClassNames, capitalizeFirstLetter } from '/utils/string';
import { ROUTES } from '/config/routes';
import { META_ENTITIES } from '/utils/constants';
import { formatScheduledDate } from '/utils/date';

import Countdown from 'react-countdown';
import pluralize from 'pluralize';
import { useGalleryDetails } from '/hooks/gallery/useGalleryDetails';

export const GallerySummary = ({
  className = '',
  alignment = 'default',
  gallery,
  curator,
  creators = [],
  collaborators,
  creatorCount,
  totalSales,
  numPieces = null,
  projectTitle = '',
  projectLink = '',
  projectLogo = '',
  onShowArtists,
  onShowCollaborators,
  onShowActivities,
  withProjectSection,
  galleryType,
  customSummary,
  withHeroAsset,
  variant
}) => {
  const router = useRouter();

  const handleViewProfile = useCallback(({ profileId }) => {
    profileId && router.push(ROUTES.profile.view({ profileId }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isSoloDrop = useMemo(
    () => creators?.length === 1 && collaborators?.length === 1,
    [collaborators, creators]
  );

  const { startDate, isScheduled } = useGalleryDetails({ gallery });

  const showStartsIn = useMemo(
    () => isScheduled && variant === 'full-page',
    [isScheduled, variant]
  );

  const refreshPage = useCallback(() => {
    window.location.reload(false);
  }, []);

  return (
    <div
      className={mergeClassNames(
        galleryType === META_ENTITIES.SHOWCASE && 'dark',
        'relative z-10 flex max-w-full flex-wrap justify-center rounded-xl',
        galleryType === META_ENTITIES.STORY ? 'gap-8' : 'gap-4',
        className,
        withHeroAsset && 'grid grid-cols-2'
      )}
    >
      {!isSoloDrop && (
        <SummaryColumn
          title="Curated by"
          galleryType={galleryType}
          alignment={alignment}
          onClick={onShowCollaborators}
        >
          {withProjectSection && collaborators?.length === 1 ? (
            <GalleryProjectSection
              title={projectTitle}
              href={projectLink}
              image={projectLogo}
              titleClassName="dark:text-white whitespace-nowrap"
              avatarRoundedSize="lg"
            />
          ) : (
            <Team
              handleProfileClick={handleViewProfile}
              labelClassName="dark:text-white"
              rounded={true}
              size={32}
              noHover={true}
              users={collaborators ? collaborators : []}
              usersCount={collaborators?.length ?? 0}
            />
          )}
        </SummaryColumn>
      )}

      {creatorCount > 1 && (
        <SummaryColumn
          title="Creators"
          galleryType={galleryType}
          alignment={alignment}
        >
          <Typography
            className="flex h-8 items-center whitespace-nowrap"
            weight="semibold"
            variant="h5"
            heading
          >
            {creatorCount}
          </Typography>
        </SummaryColumn>
      )}

      {!emptyArray(creators) && (
        <SummaryColumn
          title={pluralize('Artist', parseInt(creators?.length))}
          onClick={onShowArtists}
          galleryType={galleryType}
        >
          <Team
            handleProfileClick={handleViewProfile}
            handleShowMore={onShowArtists}
            noHover={true}
            noLabel={creators?.length !== 1}
            noWrap={true}
            required={4}
            rounded={true}
            size={32}
            users={creators}
            usersCount={creators?.length ?? 0}
          />
        </SummaryColumn>
      )}

      {numPieces > 0 && (
        <SummaryColumn
          title="Pieces"
          galleryType={galleryType}
          alignment={alignment}
        >
          <Typography
            className="flex h-8 items-center whitespace-nowrap"
            weight="semibold"
            variant="h5"
            heading
          >
            {numPieces}
          </Typography>
        </SummaryColumn>
      )}

      {/* custom summary */}
      {customSummary &&
        Object.entries(customSummary).map(([key, value]) => (
          <SummaryColumn
            key={key}
            title={capitalizeFirstLetter(key)}
            galleryType={galleryType}
            alignment={alignment}
          >
            <Typography
              className="flex h-8 items-center whitespace-nowrap"
              weight="semibold"
              variant="h5"
              heading
            >
              {value.text}
            </Typography>
          </SummaryColumn>
        ))}

      {/* scheduled */}
      {isScheduled && variant !== 'full-page' && (
        <SummaryColumn
          title="Scheduled"
          galleryType={galleryType}
          alignment={alignment}
        >
          <Typography
            className="flex h-8 items-center whitespace-nowrap"
            weight="semibold"
            variant="h5"
            heading
          >
            {formatScheduledDate(startDate)}
          </Typography>
        </SummaryColumn>
      )}

      {/* starts in */}
      {showStartsIn && (
        <SummaryColumn
          title="Starts in"
          galleryType={galleryType}
          alignment={alignment}
        >
          <Countdown
            date={startDate}
            intervalDelay={0}
            precision={3}
            onComplete={refreshPage}
            renderer={({ days, hours, minutes, seconds }) => {
              return (
                <ShortCountdownRenderer
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                  countdownStyle="gap-1 h-full items-center"
                  countdownTypographyVariant="h5"
                />
              );
            }}
          />
        </SummaryColumn>
      )}

      {/* sales info */}
      {!isScheduled && totalSales && totalSales !== '0' && (
        <SummaryColumn
          title="Total sales"
          galleryType={galleryType}
          alignment={alignment}
          onClick={onShowActivities}
        >
          <Typography
            className="flex h-8 items-center whitespace-nowrap"
            weight="semibold"
            variant="h5"
            heading
          >
            {toShort18(totalSales)} ETH
          </Typography>
        </SummaryColumn>
      )}

      {/* blurred background */}
      {galleryType === META_ENTITIES.SHOWCASE && (
        <div className="absolute left-0 top-0 z-[-1] h-full w-full rounded-xl bg-white/10 backdrop-blur-xl" />
      )}
    </div>
  );
};

const SummaryColumn = ({
  title,
  children,
  onClick,
  className,
  galleryType,
  alignment
}) => {
  return (
    <div
      className={mergeClassNames(
        'flex flex-col gap-2 rounded-lg',
        className,
        galleryType === META_ENTITIES.SHOWCASE && 'px-4 py-3',
        onClick && 'cursor-pointer hover:bg-white/10',
        alignment === 'center' && 'items-center'
      )}
      onClick={onClick}
    >
      <Typography
        className={mergeClassNames('whitespace-nowrap dark:text-white')}
        weight="medium"
      >
        {title}
      </Typography>

      {children}
    </div>
  );
};
