import React from 'react';
import EyeOffIcon from '@heroicons/react/outline/EyeOffIcon';

import { Card } from '/design-systems/Atoms/Card';
import { Typography } from '/design-systems/Atoms/Typography';
import { Image } from '/design-systems/Atoms/Image';
import { Team } from '/design-systems/Molecules/Team';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';

import { formatDate } from '/utils/date';
import { mergeClassNames } from '/utils/string';

import { useGalleryDetails } from '/hooks/gallery/useGalleryDetails';

export const StoryCard = ({ gallery, className = '' }) => {
  const { viewLink, collaborators, coverImage } = useGalleryDetails({
    gallery
  });

  return (
    <HyperlinkWrapper url={viewLink} onClick={false}>
      <Card
        id={`gallery-card-${gallery.slug}`}
        noBorder
        className={mergeClassNames(
          'gallery-card',
          '!mb-0 h-full transform !p-0 transition duration-300 hover:-translate-y-1',
          className
        )}
      >
        <div className="group flex h-full cursor-pointer flex-col">
          {/* render thumbnails */}
          <div className="max-h-[200px] min-h-[200px] w-full overflow-hidden rounded-t-xl">
            <Image
              {...coverImage}
              className="h-full w-full bg-neutral-200 object-cover"
              resolution="m"
            />
          </div>

          <div
            className={mergeClassNames(
              'flex h-full flex-col justify-between gap-3 p-4',
              'border border-t-0 border-neutral-300 dark:border-neutral-700',
              'rounded-b-xl'
            )}
          >
            <div className="flex flex-col gap-3">
              <Typography
                overflow="!line-clamp-2"
                variant="large"
                weight="medium"
              >
                {gallery.title}
              </Typography>

              <Typography
                overflow="!line-clamp-3"
                colorVariant="secondary"
                weight="normal"
              >
                {gallery.description}
              </Typography>

              <Typography
                overflow="!line-clamp-3"
                variant="small"
                colorVariant="secondary"
                weight="medium"
              >
                {gallery.status === 'published' ? (
                  formatDate(gallery.publishedAt, 'MMM D')
                ) : (
                  <span className="flex w-fit items-center gap-1 rounded-xl bg-[#F9FAFB] p-1.5">
                    <EyeOffIcon className="h-4 w-4 stroke-[#111827]" />
                    Unpublished
                  </span>
                )}
              </Typography>
            </div>

            <div className="flex items-center justify-between">
              <Team
                noHover
                noWrap
                rounded
                size={32}
                users={collaborators}
                usersCount={1}
              />
            </div>
          </div>
        </div>
      </Card>
    </HyperlinkWrapper>
  );
};
