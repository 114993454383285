import React, { useMemo } from 'react';

import { GallerySummary } from '/design-systems/Organisms/GalleryView/GallerySummary';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { Card } from '/design-systems/Atoms/Card';
import { Typography } from '/design-systems/Atoms/Typography';

import { META_ENTITIES } from '/utils/constants';
import { getCover, promptPlaceholderImg } from '/utils/image';
import { getBackgroundImageStyle } from '/utils/style';
import { ROUTES } from '/config/routes';
import { getGalleryLink } from '/utils/gallery';
import { mergeClassNames } from '/utils/string';

import { useGalleryDetails } from '/hooks/gallery/useGalleryDetails';

export const ShowcaseCard = ({
  gallery,
  className,
  onClick,
  disabledLink = false
}) => {
  const coverImg = useMemo(() => getCover(gallery), [gallery]);

  // for now, showing creatorCount because it's already in the response data
  const { curator, creatorCount, projectDetails, numPieces, collaborators } =
    useGalleryDetails({
      gallery
    });

  return (
    <HyperlinkWrapper
      url={ROUTES.showcase.view(getGalleryLink(gallery))}
      disabled={disabledLink}
    >
      <Card
        id={`gallery-card-${gallery?.slug}`}
        key={`gallery-card-${gallery?.slug}`}
        clickable={true}
        className={mergeClassNames(
          'flex flex-col',
          'dark relative z-0 mb-0 h-[520px] gap-y-6 overflow-hidden rounded-xl bg-cover bg-no-repeat',
          'items-center justify-center',
          'select-none',
          className
        )}
        style={
          coverImg
            ? getBackgroundImageStyle({ background: coverImg, dimension: 'xl' })
            : getBackgroundImageStyle({
                background: promptPlaceholderImg({ id: gallery?.id }),
                isStaticFile: true
              })
        }
        onClick={onClick}
      >
        {/* dark opaque background */}
        <div className="absolute inset-0 z-[-1] h-full rounded-xl bg-black/40"></div>

        <Typography
          heading
          variant="h1"
          weight="semibold"
          className="max-w-full text-center"
          overflow="!line-clamp-2"
        >
          {gallery?.title}
        </Typography>

        <GallerySummary
          className={'p-2'}
          alignment="center"
          gallery={gallery}
          curator={curator}
          collaborators={collaborators}
          creatorCount={creatorCount}
          totalSales={gallery?.totalSales}
          numPieces={numPieces}
          projectTitle={projectDetails.title}
          projectLink={projectDetails.link}
          projectLogo={projectDetails.logo}
          withProjectSection={gallery?.projectId}
          galleryType={META_ENTITIES.SHOWCASE}
        />
      </Card>
    </HyperlinkWrapper>
  );
};
