import React from 'react';
import { useRouter } from 'next/router';

import { Avatar } from '/design-systems/Atoms/Avatar';
import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';

export const GalleryProjectSection = ({
  title,
  image,
  href,
  titleClassName = '',
  avatarRoundedSize
}) => {
  const router = useRouter();

  return (
    <div
      id="gallery-space-title"
      className="flex max-w-full cursor-pointer items-center gap-x-2 hover:text-neutral-700"
      onClick={() => router.push(href)}
    >
      <Avatar roundedSize={avatarRoundedSize} size={32} src={image} />
      <Typography
        className={mergeClassNames('max-w-full', titleClassName)}
        variant="large"
        weight="medium"
        overflow="truncate"
      >
        {title}
      </Typography>
    </div>
  );
};
