import React from 'react';

export const GalleryCardPlaceholder = ({}) => {
  return (
    <div className='flex w-full max-w-[420px] animate-pulse cursor-pointer flex-col gap-y-1 rounded-xl border border-neutral-300'>
      <div className='flex h-[280px] items-center gap-x-0.5 overflow-hidden rounded-t-xl'>
        <div className='h-full w-2/3 bg-neutral-200 object-cover'></div>
        <div className='flex h-full w-1/3 flex-col gap-y-0.5'>
          <div className='h-1/2 bg-neutral-200 object-cover'></div>
          <div className='h-1/2 bg-neutral-200 object-cover'></div>
        </div>
      </div>

      {/* details */}
      <div className='flex flex-col gap-2 p-4'>
        <div className='h-4 w-[120px] rounded-full bg-neutral-200'></div>
        <div className='h-3 w-[80px] rounded-full bg-neutral-200'></div>
      </div>
    </div>
  );
};
