import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { usePaginatedQuery } from './usePaginatedQuery';
import {
  getAssets,
  getCreatedAssets,
  getOwnedAssets
} from '/services/joynApi/users/assets';
import { objectBlank } from '/utils/object';
import { QUERY_KEYS } from '/utils/queries';
import { toHttpsFromIPFS } from '/utils/string';
import { getNetwork } from '@wagmi/core';

export const createdAssetsResponseFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.count
});

export const ownedAssetsResponseFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.totalCount,
  page: res.data.data.page
});

export const ownedJoynAssetsResponseFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.count
});

export const assetsResponseFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.count
});

export const getFilesInfoFromOwnedAsset = (media, address) => {
  const files = [];
  if (!!media?.gateway) {
    files.push({
      fileType: media.format,
      fileUrl: toHttpsFromIPFS(
        getNetwork()?.chain?.id,
        media?.gateway,
        address
      ),
      staticFile: true
    });
  }
  if (!!media?.animation_url) {
    files.push({
      fileType: 'video/mp4',
      fileUrl: toHttpsFromIPFS(
        getNetwork()?.chain?.id,
        media.animation_url,
        address
      ),
      staticFile: true
    });
  }
  return files;
};

export const useAssets = ({
  userId,
  walletAddressId,
  getCreated = false,
  getOwned = false,
  filters,
  requiredFilterFields = [],
  assetsQueryKey,
  showHiddenInProfile = false,
  galleryId
}) => {
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingCreatedAssets,
    isFetchingNextPage: isFetchingNextCreatedAssets,
    data: createdAssetsData,
    hasNextPage: hasMoreCreatedAssets,
    fetchNextPage: fetchMoreCreatedAssets,
    refetch: refetchCreatedAssets
  } = usePaginatedQuery(
    [QUERY_KEYS.ASSET.GET_CREATED_ASSETS, userId, showHiddenInProfile],
    getCreatedAssets,
    createdAssetsResponseFormatter,
    { enabled: getCreated && !!userId, retry: false },
    { userId, filters, showHiddenInProfile }
  );

  const {
    isLoading: isLoadingOwnedAssets,
    isFetchingNextPage: isFetchingNextOwnedAssets,
    data: ownedAssetsData,
    hasNextPage: hasMoreOwnedAssets,
    fetchNextPage: fetchMoreOwnedAssets,
    refetch: refetchOwnedAssets
  } = usePaginatedQuery(
    [QUERY_KEYS.ASSET.GET_OWNED_ASSETS, userId, walletAddressId],
    getOwnedAssets,
    ownedAssetsResponseFormatter,
    {
      enabled: getOwned && !!userId && !!walletAddressId,
      retry: false,
      getNextPageParam: (lastResponse) => {
        return ownedAssetsResponseFormatter(lastResponse).page;
      }
    },
    { userId, walletAddressId, galleryId }
  );

  const {
    isLoading: isFetchingAssets,
    isFetchingNextPage: isFetchingNextAssets,
    data: assetsData,
    hasNextPage: hasMoreAssets,
    fetchNextPage: fetchMoreAssets,
    refetch: refetchAssets
  } = usePaginatedQuery(
    [QUERY_KEYS.ASSET.GET_ASSETS].concat(assetsQueryKey || []),
    getAssets,
    assetsResponseFormatter,
    {
      enabled: !!(
        assetsQueryKey &&
        !objectBlank(filters) &&
        requiredFilterFields.every((field) => !!filters[field])
      ),
      retry: false
    },
    { filters }
  );

  const { createdAssets, totalCreatedAssets } = useMemo(
    () => ({
      createdAssets: createdAssetsData?.map((item) => item.data).flat() ?? [],
      totalCreatedAssets: createdAssetsData?.[0]?.count ?? 0
    }),
    [createdAssetsData]
  );

  const { ownedAssets, totalOwnedAssets } = useMemo(
    () => ({
      ownedAssets: (ownedAssetsData?.map((item) => item.data).flat() ?? []).map(
        (asset) => ({
          ...asset,
          files:
            asset?.Files ??
            getFilesInfoFromOwnedAsset(
              asset?.media?.[0],
              asset?.contract?.address
            )
        })
      ),
      totalOwnedAssets: ownedAssetsData?.[0]?.count ?? 0
    }),
    [ownedAssetsData]
  );

  const { assets, totalAssets } = useMemo(
    () => ({
      assets: assetsData?.map((item) => item.data).flat() ?? [],
      totalAssets: assetsData?.[0]?.count ?? 0
    }),
    [assetsData]
  );

  const handleSetCreatedAssets = useCallback(
    (asset, removeAsset = false) => {
      const queryKeys = [
        QUERY_KEYS.ASSET.GET_CREATED_ASSETS,
        userId,
        showHiddenInProfile
      ];

      const [[, cachedData]] = queryClient?.getQueriesData(queryKeys);

      const chunks = {
        pages: (cachedData?.pages ?? []).map((page) => {
          /**
           * @note Page object type
           * page = {
           *  data: {
           *    data: {
           *      rows: [],
           *      count: 0,
           *    },
           * }
           * }
           */

          return {
            ...page,
            data: {
              ...page.data,
              data: {
                ...page.data.data,
                rows: page.data.data.rows
                  .map((item) => {
                    if (removeAsset) return undefined;
                    return item.id === asset.id ? asset : item;
                  })
                  .filter(Boolean)
              }
            }
          };
        }),
        pageParams: cachedData?.pageParams ?? []
      };

      queryClient?.setQueriesData(queryKeys, chunks);
    },
    [queryClient, userId, showHiddenInProfile]
  );

  return {
    isLoadingCreatedAssets,
    isFetchingNextCreatedAssets,
    createdAssets,
    totalCreatedAssets,
    hasMoreCreatedAssets,
    fetchMoreCreatedAssets,
    setCreatedAssets: handleSetCreatedAssets,
    isLoadingOwnedAssets,
    ownedAssets,
    totalOwnedAssets,
    hasMoreOwnedAssets,
    fetchMoreOwnedAssets,
    refetchOwnedAssets,
    /**
     * @deprecated use `isLoadingAssets` instead
     */
    isFetchingAssets,
    isLoadingAssets: isFetchingAssets,
    isFetchingNextAssets,
    assets,
    totalAssets,
    hasMoreAssets,
    fetchMoreAssets,
    refetchAssets,
    refetchCreatedAssets,
    isFetchingNextOwnedAssets
  };
};
