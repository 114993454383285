import React, { useMemo } from 'react';
import { mergeClassNames } from '/utils/string';

export const AssetCardPlaceholder = ({
  size = 'default',
  variant = 'default'
}) => {
  const imageHeightClassName = useMemo(
    () => (size === 'small' ? 'h-[200px]' : 'h-[360px]'),
    [size]
  );

  if (variant === 'full-row') {
    return (
      <div className="group relative flex animate-pulse flex-col items-start gap-8 md:flex-row md:items-center">
        {/* image */}
        <div className="h-[420px] w-full rounded-xl bg-neutral-200 md:h-[600px] md:w-3/5"></div>
        {/* details */}
        <div className="flex flex-col items-start gap-6">
          {/* title */}
          <div className="h-6 w-[160px] rounded-full bg-neutral-200"></div>
          {/* avatar */}
          <div className="flex items-center gap-x-2">
            <div className="h-6 w-6 rounded-full bg-neutral-200"></div>
            <div className="h-4 w-[80px] rounded-full bg-neutral-200"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="group relative flex animate-pulse flex-col rounded-xl shadow-lg">
      <div
        className={mergeClassNames(
          'w-full rounded-t-xl bg-neutral-200',
          imageHeightClassName
        )}
      ></div>
      <div className="flex items-center gap-x-2 p-3">
        <div className="h-6 w-6 rounded-full bg-neutral-200"></div>
        <div className="h-4 w-[80px] rounded-full bg-neutral-200"></div>
      </div>
    </div>
  );
};
